import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { GET_TOPIC_LIST } from '@redux/common/commonConst'
import { ActionTypes, CommonData } from '@redux/common/commonType'

const initialState = {
  isLoadingTopicList: false,
  topicList: [],
}

const commonReducer = persistReducer(
  {
    storage,
    key: 'companies',
    whitelist: [],
  },
  (state: CommonData = initialState, action: ActionTypes) => {
    switch (action.type) {
      // ==================================================
      // Topic list
      // ==================================================
      case GET_TOPIC_LIST.GET_TOPIC_LIST_REQUEST:
        return {
          ...state,
          isLoadingTopicList: true,
        }

      case GET_TOPIC_LIST.GET_TOPIC_LIST_SUCCESS:
        return {
          ...state,
          isLoadingTopicList: false,
          topicList: action.payload,
        }

      case GET_TOPIC_LIST.GET_TOPIC_LIST_ERROR:
        return {
          ...state,
          isLoadingTopicList: false,
        }

      default:
        return state
    }
  }
)

export default commonReducer
