/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux'

import { logout } from '@redux/auth/authActions'
import { dispatch } from '@redux/store'
import { errorToast, getLocalStorage, setLocalStorage } from '@util/utils'

const instance = axios.create({
  // baseURL: 'http://corporatedev.prep.study',
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const apiMiddleWare: Middleware =
  (storeApi: MiddlewareAPI<any>) =>
  (next: Dispatch<AnyAction>) =>
  (action: Action) => {
    instance.interceptors.request.use(
      (req: any) => {
        const token = getLocalStorage('accessToken')

        if (token) {
          req.headers.Authorization = token ? `Bearer ${token}` : ''
        }
        return req
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    instance.interceptors.response.use(
      (res: any) => {
        return res
      },
      (error) => {
        const originalConfig = error.config
        if (
          error.response.status === 401 &&
          error.response.config.url !== '/auth/login' &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true
          try {
            const refreshToken = getLocalStorage('refreshToken') || ''
            const formData = new FormData()
            formData.append('refresh', refreshToken)
            return instance
              .post('/api/auth/refresh-token', formData)
              .then((refreshTokenResponse) => {
                setLocalStorage(
                  'refreshToken',
                  refreshTokenResponse.data.data.refresh
                )
                setLocalStorage(
                  'accessToken',
                  refreshTokenResponse.data.data.access
                )
                originalConfig.headers[
                  'Authorization'
                ] = `Bearer ${refreshTokenResponse.data.data.access}`
                return instance(originalConfig)
              })
              .catch((er) => {
                if (
                  er.response &&
                  er.response.data &&
                  er.response.config.url === '/api/auth/refresh-token'
                ) {
                  dispatch(logout())
                  errorToast('Your session has expired. Please login again')
                }
                return Promise.reject(er.response.data)
              })
          } catch (_error: any) {
            if (_error.response && _error.response.data) {
              errorToast(
                _error.response.data?.message || 'Something went wrong'
              )
              dispatch(logout())
              return Promise.reject(_error.response.data)
            }
            return Promise.reject(_error)
          }
        }
        return Promise.reject(error)
      }
    )
    next(action)
  }
export default instance
