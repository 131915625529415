const GET_JOB_DESCRIPTION_LIST = {
  GET_JOB_DESCRIPTION_REQUEST: 'GET_JOB_DESCRIPTION_REQUEST',
  GET_JOB_DESCRIPTION_SUCCESS: 'GET_JOB_DESCRIPTION_SUCCESS',
  GET_JOB_DESCRIPTION_ERROR: 'GET_JOB_DESCRIPTION_ERROR',
}

const GET_RECRUITER_LIST = {
  GET_RECRUITER_REQUEST: 'GET_RECRUITER_REQUEST',
  GET_RECRUITER_SUCCESS: 'GET_RECRUITER_SUCCESS',
  GET_RECRUITER_ERROR: 'GET_RECRUITER_ERROR',
}

const ASSIGN_RECRUITER_TO_JD = {
  ASSIGN_RECRUITER_TO_JD_REQUEST: 'ASSIGN_RECRUITER_TO_JD_REQUEST',
  ASSIGN_RECRUITER_TO_JD_SUCCESS: 'ASSIGN_RECRUITER_TO_JD_SUCCESS',
  ASSIGN_RECRUITER_TO_JD_ERROR: 'ASSIGN_RECRUITER_TO_JD_ERROR',
}

const GET_CANDIDATE_LIST = {
  GET_CANDIDATE_REQUEST: 'GET_CANDIDATE_REQUEST',
  GET_CANDIDATE_SUCCESS: 'GET_CANDIDATE_SUCCESS',
  GET_CANDIDATE_ERROR: 'GET_CANDIDATE_ERROR',
}

const GET_JOB_DESCRIPTION_SUBMISSION_LIST = {
  GET_JOB_DESCRIPTION_SUBMISSION_REQUEST:
    'GET_JOB_DESCRIPTION_SUBMISSION_REQUEST',
  GET_JOB_DESCRIPTION_SUBMISSION_SUCCESS:
    'GET_JOB_DESCRIPTION_SUBMISSION_SUCCESS',
  GET_JOB_DESCRIPTION_SUBMISSION_ERROR: 'GET_JOB_DESCRIPTION_SUBMISSION_ERROR',
}

const REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES = {
  REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_REQUEST:
    'REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_REQUEST',
  REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_SUCCESS:
    'REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_SUCCESS',
  REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_ERROR:
    'REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_ERROR',
}

const JOB_DESCRIPTION_BOOKMARK = {
  JOB_DESCRIPTION_BOOKMARK_REQUEST: 'JOB_DESCRIPTION_BOOKMARK_REQUEST',
  JOB_DESCRIPTION_BOOKMARK_SUCCESS: 'JOB_DESCRIPTION_BOOKMARK_SUCCESS',
  JOB_DESCRIPTION_BOOKMARK_ERROR: 'JOB_DESCRIPTION_BOOKMARK_ERROR',
}

const CANDIDATE_SUBMISSION_STATE = {
  CANDIDATE_SUBMISSION_STATE_REQUEST: 'CANDIDATE_SUBMISSION_STATE_REQUEST',
  CANDIDATE_SUBMISSION_STATE_SUCCESS: 'CANDIDATE_SUBMISSION_STATE_SUCCESS',
  CANDIDATE_SUBMISSION_STATE_ERROR: 'CANDIDATE_SUBMISSION_STATE_ERROR',
}

const SUBMIT_RESUME_TO_JD = {
  SUBMIT_RESUME_TO_JD_REQUEST: 'SUBMIT_RESUME_TO_JD_REQUEST',
  SUBMIT_RESUME_TO_JD_SUCCESS: 'SUBMIT_RESUME_TO_JD_SUCCESS',
  SUBMIT_RESUME_TO_JD_ERROR: 'SUBMIT_RESUME_TO_JD_ERROR',
}

export {
  ASSIGN_RECRUITER_TO_JD,
  CANDIDATE_SUBMISSION_STATE,
  GET_CANDIDATE_LIST,
  GET_JOB_DESCRIPTION_LIST,
  GET_JOB_DESCRIPTION_SUBMISSION_LIST,
  GET_RECRUITER_LIST,
  JOB_DESCRIPTION_BOOKMARK,
  REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES,
  SUBMIT_RESUME_TO_JD,
}
