const GET_RESUME_LINK = {
  GET_RESUME_LINK_INITIALIZATION: 'GET_RESUME_LINK_INITIALIZATION',
  GET_RESUME_LINK_SUCCESS: 'GET_RESUME_LINK_SUCCESS',
  GET_RESUME_LINK_FAILED: 'GET_RESUME_LINK_FAILED',
}

const GET_RESUME_FILE = {
  GET_RESUME_FILE_INITIALIZATION: 'GET_RESUME_FILE_INITIALIZATION',
  GET_RESUME_FILE_SUCCESS: 'GET_RESUME_FILE_SUCCESS',
  GET_RESUME_FILE_ERROR: 'GET_RESUME_FILE_ERROR',
}

const UPLOAD_RESUME = {
  UPLOAD_RESUME_REQUEST: 'UPLOAD_RESUME_REQUEST',
  UPLOAD_RESUME_SUCCESS: 'UPLOAD_RESUME_SUCCESS',
  UPLOAD_RESUME_ERROR: 'UPLOAD_RESUME_ERROR',
}

const SEND_UPDATED_RESUME_DATA = {
  SEND_UPDATED_RESUME_DATA_INITIALIZATION:
    'SEND_UPDATED_RESUME_DATA_INITIALIZATION',
}
const SEND_DUPLICATE_RESUME_DATA = {
  SEND_DUPLICATE_RESUME_DATA_INITIALIZATION:
    'SEND_DUPLICATE_RESUME_DATA_INITIALIZATION',
}

const UPLOADED_RESUME_LIST = {
  UPLOADED_RESUME_LIST_INITIALIZATION: 'UPLOADED_RESUME_LIST_INITIALIZATION',
  SEND_UPLOADED_RESUME_LIST: 'SEND_UPLOADED_RESUME_LIST',
}

export {
  GET_RESUME_FILE,
  GET_RESUME_LINK,
  SEND_DUPLICATE_RESUME_DATA,
  SEND_UPDATED_RESUME_DATA,
  UPLOAD_RESUME,
  UPLOADED_RESUME_LIST,
}
