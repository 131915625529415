/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  BOOKMARK_QUESTION_PAPER,
  CREATE_PAPER,
  EDIT_QUESTION,
  EDIT_QUESTION_PAPER,
  GET_EXAM_TYPES,
  GET_OVERALL_QUESTION_PAPER_RATING,
  GET_QUESTION_PAPER_RATING_LIST,
  GET_RECRUITMENT_QUESTION_PAPER_DETAILS,
  PAPER_DETAILS,
  QUESTION_PAPER_DELETE,
  QUESTION_PAPER_DETAILS,
  QUESTION_PAPER_LIST,
  QUESTION_PAPER_UPLOAD,
  REMOVE_CART_ITEMS,
  SET_CART_ITEMS,
  UPLOAD_PAPER_FILE,
} from '@redux/question-paper/questionPaperConst'
import {
  ActionTypes,
  QuestionPaperData,
} from '@redux/question-paper/questionPaperType'

const initialState = {
  isLoading: false,
  errorMessage: '',
  questionPaperList: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  questionPaperDetails: {},
  itemsInCart: [],
  isQuestionPaperDetailLoading: false,
  isQuestionPaperListLoading: false,
  isDeleteQuestionPaperLoading: false,
  isLoadingQuestionPaperUpload: false,
  isLoadingQuestionCreate: false,
  examTypes: [],
  paperDetails: {},
  QuestionPaperData: [],
  QuestionPaperError: [],
  isLoadingEdit: false,
  recruitmentQuestionPaperDetails: {
    id: 0,
    topics: [
      {
        id: 0,
        name: '',
      },
    ],
    professional_details: {
      id: 0,
      first_name: '',
      last_name: '',
      uploaded_question_paper: 0,
      user: 0,
      bio: '',
      profile_banner: '',
      photo: '',
    },
  },
  isLoadingRecruitmentQuestionPaperDetails: false,
  overallQuestionPaperRating: { average_rating: 0, rating_count: 0 },
  isLoadingOverallQuestionPaperRating: false,
  questionPaperRatingList: [
    {
      id: '',
      comment: '',
      rating: '',
      reviewed_at: '',
      reviewed_by: {
        first_name: '',
        id: '',
        last_name: '',
      },
    },
  ],
  isLoadingQuestionPaperRatingList: false,
  isLoadingQuestionPaperBookmark: false,
}

const questionPaperReducer = persistReducer(
  {
    storage,
    key: 'questionPaper',
    whitelist: [
      'questionPaperList',
      'questionPaperDetails',
      'examTypes',
      'paperDetails',
      'QuestionPaperData',
      'QuestionPaperError',
    ],
  },
  (state: QuestionPaperData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isQuestionPaperListLoading: true,
        }

      case QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_SUCCESS:
        const papers: Array<any> = action.payload
        return {
          ...state,
          errorMessage: '',
          isQuestionPaperListLoading: false,
          questionPaperList: papers,
        }

      case QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_ERROR:
        return {
          ...state,
          errorMessage: '',
          isQuestionPaperListLoading: false,
        }
      case QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isQuestionPaperDetailLoading: true,
        }

      case QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_SUCCESS:
        const questionPaper: object = action.payload
        return {
          ...state,
          errorMessage: '',
          isQuestionPaperDetailLoading: false,
          questionPaperDetails: questionPaper,
        }

      case QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_ERROR:
        return {
          ...state,
          errorMessage: '',
          isQuestionPaperDetailLoading: false,
        }
      case QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_REQUEST:
        return {
          ...state,
          isDeleteQuestionPaperLoading: true,
        }
      case QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_SUCCESS:
        return {
          ...state,
          isDeleteQuestionPaperLoading: false,
        }
      case QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_ERROR:
        return {
          ...state,
          isDeleteQuestionPaperLoading: false,
        }
      case QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }
      case QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_ERROR:
        return {
          ...state,
          isLoading: false,
        }

      case SET_CART_ITEMS.SET_CART_ITEMS:
        const items = []
        const cartFromLS = JSON.parse(localStorage.getItem('cartItems')!) || []
        if (state.itemsInCart.length > 0) {
          localStorage.setItem('cartItems', JSON.stringify(state.itemsInCart))
        }
        if (
          !(
            state.itemsInCart.length == 0 &&
            Object.keys(action.payload).length == 0 &&
            cartFromLS.length == 0
          )
        ) {
          const items = state.itemsInCart
            ? [...new Set([...state.itemsInCart, action.payload])]
            : [...new Set([...cartFromLS, action.payload])]
        }

        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          itemsInCart: items,
        }

      case REMOVE_CART_ITEMS.REMOVE_CART_ITEMS:
        let currentCartItems = state.itemsInCart
        currentCartItems = currentCartItems.filter(
          (el) => (el as any).id != (action.payload as any).id
        )
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          itemsInCart: currentCartItems,
        }

      /**
       * ==================================================
       * Question paper tab details
       * ==================================================
       */

      case PAPER_DETAILS.PAPER_DETAILS:
        return {
          ...state,
          paperDetails: action.payload,
        }

      /**
       * ==================================================
       * Question paper upload
       * ==================================================
       */
      case UPLOAD_PAPER_FILE.UPLOAD_PAPER_FILE_REQUEST:
        return {
          ...state,
          isLoadingQuestionPaperUpload: true,
        }

      case UPLOAD_PAPER_FILE.UPLOAD_PAPER_FILE_SUCCESS:
        const paperData: Record<string, any> = action.payload
        return {
          ...state,
          isLoadingQuestionPaperUpload: false,
          QuestionPaperData: paperData,
        }

      case UPLOAD_PAPER_FILE.UPLOAD_PAPER_FILE_ERROR:
        return {
          ...state,
          QuestionPaperError: action.payload,
          isLoadingQuestionPaperUpload: false,
        }

      /**
       * ==================================================
       * crete paper
       * ==================================================
       */
      case CREATE_PAPER.CREATE_PAPER_REQUEST:
        return {
          ...state,
          isLoadingQuestionCreate: true,
        }

      case CREATE_PAPER.CREATE_PAPER_SUCCESS:
        return {
          ...state,
          isLoadingQuestionCreate: false,
        }

      case CREATE_PAPER.CREATE_PAPER_ERROR:
        return {
          ...state,
          isLoadingQuestionCreate: false,
        }

      /**
       * ==================================================
       * Exam type list
       * ==================================================
       */
      case GET_EXAM_TYPES.GET_EXAM_TYPES_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case GET_EXAM_TYPES.GET_EXAM_TYPES_SUCCESS:
        const examType: Record<string, any> = action.payload
        return {
          ...state,
          isLoading: false,
          examTypes: examType,
        }

      case GET_EXAM_TYPES.GET_EXAM_TYPES_ERROR:
        return {
          ...state,
          isLoading: false,
        }

      /**
       * ==================================================
       * Edit question
       * ==================================================
       */
      case EDIT_QUESTION.EDIT_QUESTION_REQUEST:
        return {
          ...state,
          isLoadingEdit: true,
        }

      case EDIT_QUESTION.EDIT_QUESTION_SUCCESS:
        return {
          ...state,
          isLoadingEdit: false,
        }

      case EDIT_QUESTION.EDIT_QUESTION_ERROR:
        return {
          ...state,
          isLoadingEdit: false,
        }

      /**
       * ==================================================
       * Edit question paper
       * ==================================================
       */
      case EDIT_QUESTION_PAPER.EDIT_QUESTION_PAPER_REQUEST:
        return {
          ...state,
          isLoadingEdit: true,
        }

      case EDIT_QUESTION_PAPER.EDIT_QUESTION_PAPER_SUCCESS:
        return {
          ...state,
          isLoadingEdit: false,
        }

      case EDIT_QUESTION_PAPER.EDIT_QUESTION_PAPER_ERROR:
        return {
          ...state,
          isLoadingEdit: false,
        }

      /**
       * ====================================================
       * Get recruitment question paper details with SME Info
       * ====================================================
       */
      case GET_RECRUITMENT_QUESTION_PAPER_DETAILS.GET_RECRUITMENT_QUESTION_PAPER_DETAILS_REQUEST:
        return {
          ...state,
          isLoadingRecruitmentQuestionPaperDetails: true,
        }

      case GET_RECRUITMENT_QUESTION_PAPER_DETAILS.GET_RECRUITMENT_QUESTION_PAPER_DETAILS_SUCCESS:
        return {
          ...state,
          isLoadingRecruitmentQuestionPaperDetails: false,
          recruitmentQuestionPaperDetails: action.payload as any,
        }

      case GET_RECRUITMENT_QUESTION_PAPER_DETAILS.GET_RECRUITMENT_QUESTION_PAPER_DETAILS_ERROR:
        return {
          ...state,
          isLoadingRecruitmentQuestionPaperDetails: false,
        }

      /**
       * ====================================================
       * Get overall question paper rating
       * ====================================================
       */
      case GET_OVERALL_QUESTION_PAPER_RATING.GET_OVERALL_QUESTION_PAPER_RATING_REQUEST:
        return {
          ...state,
          isLoadingOverallQuestionPaperRating: true,
        }

      case GET_OVERALL_QUESTION_PAPER_RATING.GET_OVERALL_QUESTION_PAPER_RATING_SUCCESS:
        return {
          ...state,
          isLoadingOverallQuestionPaperRating: false,
          overallQuestionPaperRating: action.payload as any,
        }

      case GET_OVERALL_QUESTION_PAPER_RATING.GET_OVERALL_QUESTION_PAPER_RATING_ERROR:
        return {
          ...state,
          isLoadingOverallQuestionPaperRating: false,
        }

      /**
       * ====================================================
       * Get question paper rating list
       * ====================================================
       */
      case GET_QUESTION_PAPER_RATING_LIST.GET_QUESTION_PAPER_RATING_LIST_REQUEST:
        return {
          ...state,
          isLoadingQuestionPaperRatingList: true,
        }

      case GET_QUESTION_PAPER_RATING_LIST.GET_QUESTION_PAPER_RATING_LIST_SUCCESS:
        return {
          ...state,
          isLoadingQuestionPaperRatingList: false,
          questionPaperRatingList: action.payload as any,
        }

      case GET_QUESTION_PAPER_RATING_LIST.GET_QUESTION_PAPER_RATING_LIST_ERROR:
        return {
          ...state,
          isLoadingQuestionPaperRatingList: false,
        }

      /**
       * ====================================================
       * Bookmark question paper
       * ====================================================
       */
      case BOOKMARK_QUESTION_PAPER.BOOKMARK_QUESTION_PAPER_REQUEST:
        return {
          ...state,
          isLoadingQuestionPaperBookmark: true,
        }

      case BOOKMARK_QUESTION_PAPER.BOOKMARK_QUESTION_PAPER_SUCCESS:
      case BOOKMARK_QUESTION_PAPER.BOOKMARK_QUESTION_PAPER_ERROR:
        return {
          ...state,
          isLoadingQuestionPaperBookmark: false,
        }

      default:
        return state
    }
  }
)

export default questionPaperReducer
