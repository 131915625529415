/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  AGENCY_DETAILS,
  EXPERTISE_LIST,
  ORGANISATION_CREATE,
  ORGANISATION_DETAILS,
  ORGANISATION_UPDATE,
  SERVICE_LIST,
  TOOL_LIST,
} from '@redux/organisation/organisationConst'
import {
  ActionTypes,
  organisationData,
} from '@redux/organisation/organisationType'

const initialState = {
  isLoading: false,
  errorMessage: '',
  organisationDetails: {},
  organisationEditDetails: {},
  expertisesList: [],
  servicesList: [],
  toolsList: [],
  isLoadingOrganisationProfileSetup: false,
  organisationProfileSetup: {
    basicCompanyDetails: {},
    serviceDetails: {},
    socialLinks: {},
    kycDocuments: {},
  },
  isLoadingAgencyDetails: false,
  agencyDetails: {},
}

const organisationReducer = persistReducer(
  {
    storage,
    key: 'organization_profile',
    whitelist: ['organisationDetails', 'organisationEditDetails'],
  },
  (state: organisationData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case ORGANISATION_CREATE.ORGANISATION_CREATE_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case ORGANISATION_CREATE.ORGANISATION_CREATE_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case ORGANISATION_CREATE.ORGANISATION_CREATE_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case ORGANISATION_DETAILS.ORGANISATION_DETAILS_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case ORGANISATION_DETAILS.ORGANISATION_DETAILS_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          organisationDetails: action.payload,
        }

      case ORGANISATION_DETAILS.ORGANISATION_DETAILS_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }
      case ORGANISATION_UPDATE.ORGANISATION_UPDATE_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoadingOrganisationProfileSetup: true,
          isLoading: true,
        }

      case ORGANISATION_UPDATE.ORGANISATION_UPDATE_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          isLoadingOrganisationProfileSetup: false,
          organisationEditDetails: action.payload,
        }

      case ORGANISATION_UPDATE.ORGANISATION_UPDATE_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          isLoadingOrganisationProfileSetup: false,
        }
      case EXPERTISE_LIST.EXPERTISE_LIST_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case EXPERTISE_LIST.EXPERTISE_LIST_SUCCESS:
        const expertises: Array<any> = action.payload
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          expertisesList: expertises,
        }

      case EXPERTISE_LIST.EXPERTISE_LIST_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }
      case SERVICE_LIST.SERVICE_LIST_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case SERVICE_LIST.SERVICE_LIST_SUCCESS:
        const services: Array<any> = action.payload
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          servicesList: services,
        }

      case SERVICE_LIST.SERVICE_LIST_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }
      case TOOL_LIST.TOOL_LIST_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }
      case TOOL_LIST.TOOL_LIST_SUCCESS:
        const tools: Array<any> = action.payload
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          toolsList: tools,
        }

      case TOOL_LIST.TOOL_LIST_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case AGENCY_DETAILS.AGENCY_DETAILS_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoadingAgencyDetails: true,
        }

      case AGENCY_DETAILS.AGENCY_DETAILS_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoadingAgencyDetails: false,
          agencyDetails: action.payload,
        }

      case AGENCY_DETAILS.AGENCY_DETAILS_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoadingAgencyDetails: false,
        }

      default:
        return state
    }
  }
)

export default organisationReducer
