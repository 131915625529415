import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  ADD_EMPLOYEE,
  EMPLOYEE_ASSIGNMENT_HISTORY,
  EMPLOYEE_JOB_DESCRIPTION_ASSIGNED,
  EMPLOYEE_JOB_DESCRIPTION_DETAILS,
  GET_EMPLOYEE_DETAILS,
  GET_EMPLOYEE_LIST,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_STATUS,
  UPLOAD_EMPLOYEE,
} from '@redux/employee-management/employeeConst'
import {
  ActionTypes,
  EmployeeData,
} from '@redux/employee-management/employeeType'

const initialState = {
  isLoadingEmployeeList: false,
  employeeList: {
    count: 0,
    results: [],
  },
  isLoadingEmployeeDetails: false,
  employeeDetails: {},
  isLoadingAddEmployee: false,
  isLoadingUpdateEmployee: false,
  isLoadingUpdateEmployeeStatus: false,
  isLoadingUploadEmployee: false,
  employeeAssignmentHistory: {
    count: 0,
    results: [],
  },
  isLoadingEmployeeAssignmentHistory: false,
  employeeJobDescriptionAssignment: {
    count: 0,
    results: [],
  },
  isLoadingEmployeeDescriptionAssignment: false,
  employeeJobDescriptionDetails: {},
  isLoadingEmployeeDescriptionDetails: false,
}

const employeeReducer = persistReducer(
  {
    storage,
    key: 'employee',
    whitelist: [],
  },
  (state: EmployeeData = initialState, action: ActionTypes) => {
    switch (action.type) {
      // ==================================================
      // Add employee
      // ==================================================
      case ADD_EMPLOYEE.ADD_EMPLOYEE_REQUEST:
        return {
          ...state,
          isLoadingAddEmployee: true,
        }

      case ADD_EMPLOYEE.ADD_EMPLOYEE_SUCCESS:
        return {
          ...state,
          isLoadingAddEmployee: false,
        }

      case ADD_EMPLOYEE.ADD_EMPLOYEE_ERROR:
        return {
          ...state,
          isLoadingAddEmployee: false,
        }
      // ==================================================
      // Employee list
      // ==================================================
      case GET_EMPLOYEE_LIST.GET_EMPLOYEE_LIST_REQUEST:
        return {
          ...state,
          isLoadingEmployeeList: true,
        }

      case GET_EMPLOYEE_LIST.GET_EMPLOYEE_LIST_SUCCESS:
        return {
          ...state,
          isLoadingEmployeeList: false,
          employeeList: action.payload,
        }

      case GET_EMPLOYEE_LIST.GET_EMPLOYEE_LIST_ERROR:
        return {
          ...state,
          isLoadingEmployeeList: false,
        }

      // ==================================================
      // Employee details
      // ==================================================

      case GET_EMPLOYEE_DETAILS.GET_EMPLOYEE_DETAILS_REQUEST:
        return {
          ...state,
          isLoadingEmployeeDetails: true,
        }

      case GET_EMPLOYEE_DETAILS.GET_EMPLOYEE_DETAILS_SUCCESS:
        return {
          ...state,
          isLoadingEmployeeDetails: false,
          employeeDetails: action.payload,
        }

      case GET_EMPLOYEE_DETAILS.GET_EMPLOYEE_DETAILS_ERROR:
        return {
          ...state,
          isLoadingEmployeeDetails: false,
        }
      // ==================================================
      // Update employee
      // ==================================================
      case UPDATE_EMPLOYEE.UPDATE_EMPLOYEE_REQUEST:
        return {
          ...state,
          isLoadingUpdateEmployee: true,
        }

      case UPDATE_EMPLOYEE.UPDATE_EMPLOYEE_SUCCESS:
        return {
          ...state,
          isLoadingUpdateEmployee: false,
        }

      case UPDATE_EMPLOYEE.UPDATE_EMPLOYEE_ERROR:
        return {
          ...state,
          isLoadingUpdateEmployee: false,
        }

      // ==================================================
      // Update employee
      // ==================================================
      case UPDATE_EMPLOYEE_STATUS.UPDATE_EMPLOYEE_STATUS_REQUEST:
        return {
          ...state,
          isLoadingUpdateEmployeeStatus: true,
        }

      case UPDATE_EMPLOYEE_STATUS.UPDATE_EMPLOYEE_STATUS_SUCCESS:
        return {
          ...state,
          isLoadingUpdateEmployeeStatus: false,
        }

      case UPDATE_EMPLOYEE_STATUS.UPDATE_EMPLOYEE_STATUS_ERROR:
        return {
          ...state,
          isLoadingUpdateEmployeeStatus: false,
        }

      // ==================================================
      // Add employee
      // ==================================================
      case UPLOAD_EMPLOYEE.UPLOAD_EMPLOYEE_REQUEST:
        return {
          ...state,
          isLoadingUploadEmployee: true,
        }

      case UPLOAD_EMPLOYEE.UPLOAD_EMPLOYEE_SUCCESS:
        return {
          ...state,
          isLoadingUploadEmployee: false,
        }

      case UPLOAD_EMPLOYEE.UPLOAD_EMPLOYEE_ERROR:
        return {
          ...state,
          isLoadingUploadEmployee: false,
        }

      // ==================================================
      // Employee assignment history
      // ==================================================
      case EMPLOYEE_ASSIGNMENT_HISTORY.EMPLOYEE_ASSIGNMENT_HISTORY_REQUEST:
        return {
          ...state,
          isLoadingEmployeeAssignmentHistory: true,
        }

      case EMPLOYEE_ASSIGNMENT_HISTORY.EMPLOYEE_ASSIGNMENT_HISTORY_SUCCESS:
        return {
          ...state,
          isLoadingEmployeeAssignmentHistory: false,
          employeeAssignmentHistory: action.payload,
        }

      case EMPLOYEE_ASSIGNMENT_HISTORY.EMPLOYEE_ASSIGNMENT_HISTORY_ERROR:
        return {
          ...state,
          isLoadingEmployeeAssignmentHistory: false,
        }

      // ==================================================
      // Employee job description assignment
      // ==================================================
      case EMPLOYEE_JOB_DESCRIPTION_ASSIGNED.EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_REQUEST:
        return {
          ...state,
          isLoadingEmployeeDescriptionAssignment: true,
        }

      case EMPLOYEE_JOB_DESCRIPTION_ASSIGNED.EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_SUCCESS:
        return {
          ...state,
          isLoadingEmployeeDescriptionAssignment: false,
          employeeJobDescriptionAssignment: action.payload,
        }

      case EMPLOYEE_JOB_DESCRIPTION_ASSIGNED.EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_ERROR:
        return {
          ...state,
          isLoadingEmployeeDescriptionAssignment: false,
        }

      // ==================================================
      // Employee job description details
      // ==================================================
      case EMPLOYEE_JOB_DESCRIPTION_DETAILS.EMPLOYEE_JOB_DESCRIPTION_DETAILS_REQUEST:
        return {
          ...state,
          isLoadingEmployeeDescriptionDetails: true,
        }

      case EMPLOYEE_JOB_DESCRIPTION_DETAILS.EMPLOYEE_JOB_DESCRIPTION_DETAILS_SUCCESS:
        return {
          ...state,
          isLoadingEmployeeDescriptionDetails: false,
          employeeJobDescriptionDetails: action.payload,
        }

      case EMPLOYEE_JOB_DESCRIPTION_DETAILS.EMPLOYEE_JOB_DESCRIPTION_DETAILS_ERROR:
        return {
          ...state,
          isLoadingEmployeeDescriptionDetails: false,
        }
      default:
        return state
    }
  }
)

export default employeeReducer
