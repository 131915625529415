import CircularProgress from '@mui/material/CircularProgress'

export const Loader = (props) => {
  const overlay: Record<string, string> = {
    width: '100%',
    height: props?.height || '100%',
  }

  const overlayInner: Record<string, string> = {
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    position: 'relative',
  }

  const overlayContent: Record<string, string> = {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  }

  return (
    <div style={overlay}>
      <div style={overlayInner}>
        <div style={overlayContent}>
          <CircularProgress color="info" />
        </div>
      </div>
    </div>
  )
}
