/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify'
import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  ACCOUNT_VERIFICATION,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  GET_PROFILE_DETAILS,
  LOGIN,
  LOGOUT,
  PROFILE_UPDATE,
  REGISTER,
  RESEND_USER_EMAIL_VARIFICATION_CODE,
  RESET_PASSWORD,
  SEND_EMAIL_VARIFICATION_CODE,
  UPDATE_PASSWORD,
  VARIFY_EMAIL_OTP,
} from '@redux/auth/authConst'
import {
  PASSWORD_CHANGE_URL,
  PASSWORD_RESET_URL,
  PROFILE_URL,
  RESEND_USER_EMAIL_VARIFICATION_CODE_URL,
  SEND_EMAIL_VARIFICATION_CODE_URL,
  UPDATE_PASSWORD_URL,
  USER_ACCOUNT_VERIFICATION,
  USER_LOGIN,
  USER_REGISTER,
  VARIFY_EMAIL_URL,
  VERIFY_PASSWORD_URL,
} from '@service/apiEndPoints'
import { apiCall, METHOD } from '@service/baseApiCall'
import { errorToast, setLocalStorage, successToast } from '@util/utils'

export const register =
  (
    payload,
    _handleSuccess: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REGISTER.REGISTER_REQUEST,
    })
    apiCall(
      USER_REGISTER,
      (res: any) => {
        dispatch({
          type: REGISTER.REGISTER_SUCCESS,
          payload: res?.data,
        })
        if (_handleSuccess) {
          _handleSuccess()
        }
        successToast('Please verify your email to activate your account.')
      },
      (errData: any) => {
        dispatch({
          type: REGISTER.REGISTER_ERROR,
        })
        const field = Object.keys(errData?.data)[0]
        const emailError =
          'email' === field && 'This email is already registered with agency.'
        errorToast(emailError || 'Something went wrong.')
      },
      payload,
      METHOD.POST,
      { showToast: false }
    )
  }

export const accountVerification =
  (
    verificationCode,
    _handleSuccess: () => void,
    _handleFailure: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ACCOUNT_VERIFICATION.ACCOUNT_VERIFICATION_REQUEST,
    })
    apiCall(
      USER_ACCOUNT_VERIFICATION.replace(
        '<verification_code>',
        verificationCode
      ),
      (res: any) => {
        dispatch({
          type: ACCOUNT_VERIFICATION.ACCOUNT_VERIFICATION_SUCCESS,
          payload: res?.data,
        })
        if (_handleSuccess) {
          _handleSuccess()
        }
      },
      () => {
        dispatch({
          type: ACCOUNT_VERIFICATION.ACCOUNT_VERIFICATION_ERROR,
        })
        if (_handleFailure) {
          _handleFailure()
        }
      },
      {},
      METHOD.GET,
      { showToast: true }
    )
  }

export const passwordChange =
  (
    payload,
    _handleSuccess: () => void,
    _handleFailure: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_REQUEST,
    })
    apiCall(
      PASSWORD_CHANGE_URL,
      (res: any) => {
        dispatch({
          type: CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS,
          payload: res?.data,
        })
        if (_handleSuccess) {
          _handleSuccess()
        }
      },
      () => {
        dispatch({
          type: CHANGE_PASSWORD.CHANGE_PASSWORD_ERROR,
        })
      },
      payload,
      METHOD.POST,
      { showToast: true }
    )
  }

export const login =
  (
    username: string,
    password: string,
    _handleSuccess: (...args) => void,
    _handleFailure: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: LOGIN.LOGIN_REQUEST,
    })
    apiCall(
      USER_LOGIN,
      (res: any) => {
        setLocalStorage('refreshToken', res.data.refresh)
        setLocalStorage('accessToken', res.data.access)
        dispatch({
          type: LOGIN.LOGIN_SUCCESS,
          payload: res?.data,
        })
        if (
          res?.data?.user?.role !== 'Recruitment Head' &&
          res?.data?.user?.role !== 'Recruiting Manager' &&
          res?.data?.user?.role !== 'Recruiter'
        ) {
          errorToast('Invalid credentials')
        } else {
          _handleSuccess(res)
          successToast(res?.message)
        }
      },
      () => {
        dispatch({
          type: LOGIN.LOGIN_ERROR,
        })
      },
      {
        username,
        password,
        is_recruiter: true,
      },
      METHOD.POST,
      { showToast: false }
    )
  }

// ************** Logout ******************

export const logout =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    localStorage.clear()
    dispatch({
      type: LOGOUT.LOGOUT_SUCCESS,
    })
  }

// ************** Refresh token data ******************
// export const refreshTokenUpdate =
//   (res): ThunkAction<void, Store, unknown, Action<string>> =>
//   (dispatch) => {
//     dispatch({
//       type: LOGIN.LOGIN_SUCCESS,
//       payload: res,
//     })
//   }
export const forgotPassword =
  (
    email: string,
    _handleSuccess: () => void,
    _handleFailure: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST,
    })
    apiCall(
      PASSWORD_RESET_URL,
      (res: any) => {
        dispatch({
          type: FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS,
          payload: res?.data,
        })
        if (_handleSuccess) _handleSuccess()
      },
      () => {
        dispatch({
          type: FORGOT_PASSWORD.FORGOT_PASSWORD_ERROR,
        })
      },
      {
        email: email,
      },
      METHOD.POST,
      { showToast: true }
    )
  }

export const resetPassword =
  (
    payload: object,
    _handleSuccess: () => void,
    _handleFailure: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: RESET_PASSWORD.RESET_PASSWORD_REQUEST,
    })
    apiCall(
      VERIFY_PASSWORD_URL,
      (res: any) => {
        dispatch({
          type: RESET_PASSWORD.RESET_PASSWORD_SUCCESS,
          payload: res?.data,
        })
        if (_handleSuccess) _handleSuccess()
      },
      () => {
        dispatch({
          type: RESET_PASSWORD.RESET_PASSWORD_ERROR,
        })
      },
      payload,
      METHOD.POST,
      { showToast: true }
    )
  }
export const getProfile =
  (ID: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_PROFILE_DETAILS.GET_PROFILE_DETAILS_REQUEST,
    })
    apiCall(
      PROFILE_URL + `/${ID}`,
      (res: any) => {
        dispatch({
          type: GET_PROFILE_DETAILS.GET_PROFILE_DETAILS_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_PROFILE_DETAILS.GET_PROFILE_DETAILS_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

export const updateProfile =
  (ID, payload): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: PROFILE_UPDATE.PROFILE_UPDATE_REQUEST,
    })
    apiCall(
      PROFILE_URL + `/${ID}`,
      (res: any) => {
        dispatch({
          type: PROFILE_UPDATE.PROFILE_UPDATE_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: PROFILE_UPDATE.PROFILE_UPDATE_ERROR,
        })
      },
      payload,
      METHOD.PUT,
      { showToast: true }
    )
  }

export const updatePassword =
  (payload): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_PASSWORD.UPDATE_PASSWORD_REQUEST,
    })
    apiCall(
      UPDATE_PASSWORD_URL,
      (res: any) => {
        dispatch({
          type: UPDATE_PASSWORD.UPDATE_PASSWORD_SUCCESS,
          payload: res?.data,
        })
      },
      (res: any) => {
        dispatch({
          type: UPDATE_PASSWORD.UPDATE_PASSWORD_ERROR,
        })
        res?.data?.current_password &&
          toast.error(res?.data?.current_password.toString())
        res?.data?.password && toast.error(res?.data?.password.toString())
      },
      payload,
      METHOD.POST,
      { showToast: false }
    )
  }

export const sendEmailVarifyThunk =
  (
    email: string,
    handleOpenEmail?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SEND_EMAIL_VARIFICATION_CODE.SEND_EMAIL_VARIFICATION_CODE_REQUEST,
    })
    apiCall(
      SEND_EMAIL_VARIFICATION_CODE_URL,
      (res: any) => {
        dispatch({
          type: SEND_EMAIL_VARIFICATION_CODE.SEND_EMAIL_VARIFICATION_CODE_SUCCESS,
        })
        handleOpenEmail && handleOpenEmail()
      },
      (err: any) => {
        dispatch({
          type: SEND_EMAIL_VARIFICATION_CODE.SEND_EMAIL_VARIFICATION_CODE_ERROR,
        })
      },
      {
        email,
      },
      METHOD.POST,
      { showToast: false }
    )
  }

export const varifyEmailOTPThunk =
  (
    payload,
    _handleSuccess?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VARIFY_EMAIL_OTP.VARIFY_EMAIL_OTP_REQUEST,
    })
    apiCall(
      VARIFY_EMAIL_URL,
      (res: any) => {
        dispatch({
          type: VARIFY_EMAIL_OTP.VARIFY_EMAIL_OTP_SUCCESS,
          payload: res?.status,
        })
        _handleSuccess && _handleSuccess()
      },
      () => {
        dispatch({
          type: VARIFY_EMAIL_OTP.VARIFY_EMAIL_OTP_ERROR,
        })
      },
      payload,
      METHOD.PUT,
      { showToast: true }
    )
  }

export const sendUserEmailVarifyThunk =
  (email: string): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: RESEND_USER_EMAIL_VARIFICATION_CODE.RESEND_USER_EMAIL_VARIFICATION_CODE_REQUEST,
    })
    apiCall(
      RESEND_USER_EMAIL_VARIFICATION_CODE_URL,
      (res: any) => {
        dispatch({
          type: RESEND_USER_EMAIL_VARIFICATION_CODE.RESEND_USER_EMAIL_VARIFICATION_CODE_SUCCESS,
        })
      },
      (err: any) => {
        dispatch({
          type: RESEND_USER_EMAIL_VARIFICATION_CODE.RESEND_USER_EMAIL_VARIFICATION_CODE_ERROR,
        })
      },
      {
        email,
      },
      METHOD.POST,
      { showToast: true }
    )
  }
