/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import Axios from 'axios'

import axios from '@service/axiosConfig'
import { errorToast, successToast } from '@util/utils'

export const METHOD = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
}

export const apiCall = (
  endpoint: string,
  onSuccess: Function,
  onFailure: Function,
  params = {},
  method = METHOD.GET,
  DynamicConfig: any
) => {
  const request = (): Promise<any> => {
    switch (method) {
      case METHOD.POST:
        return axios.post(endpoint, params, { ...DynamicConfig })
      case METHOD.GET:
        return axios.get(endpoint, { ...params })
      case METHOD.DELETE:
        return axios.delete(endpoint, { ...DynamicConfig })
      case METHOD.PUT:
        return axios.put(endpoint, params, { ...DynamicConfig })
      case METHOD.PATCH:
        return axios.patch(endpoint, params, { ...DynamicConfig })
    }
    return new Promise((resolve) => resolve({}))
  }

  const req = request()

  req
    .then((response) => {
      if (
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 204 ||
          response.data) &&
        response.data !== ''
      ) {
        if (DynamicConfig.showToast && response.data.message) {
          successToast(response.data.message)
        }
        onSuccess(response.data)
      } else {
        onFailure('Something went wrong')
      }
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.data?.message?.non_field_errors) {
          errorToast(
            error.response.data?.message?.non_field_errors?.[0] ||
              'Something went wrong'
          )
        } else if (
          DynamicConfig.showToast &&
          (error.response.data.message ||
            error.response.data.message?.distribution)
        ) {
          errorToast(
            error.response.data.message.distribution ||
              error.response.data.message ||
              'Something went wrong'
          )
          onFailure(error?.response)
          return
        }
        switch (error.response.status) {
          case 401:
            onFailure(error.response)
            break
          default:
            onFailure(
              error.response.data ? error.response.data : 'Something went wrong'
            )
            errorToast(error?.response?.data?.message || 'Something went wrong')
            break
        }
      } else {
        if (Axios.isCancel(error)) {
          return
        }
        onFailure && onFailure('Something went wrong')
        !window.navigator.onLine
          ? errorToast('Please check your internet connection')
          : errorToast('Something went wrong')
      }
    })
}
