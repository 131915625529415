const ADD_EMPLOYEE = {
  ADD_EMPLOYEE_REQUEST: 'ADD_EMPLOYEE_REQUEST',
  ADD_EMPLOYEE_SUCCESS: 'ADD_EMPLOYEE_SUCCESS',
  ADD_EMPLOYEE_ERROR: 'ADD_EMPLOYEE_ERROR',
}

const GET_EMPLOYEE_LIST = {
  GET_EMPLOYEE_LIST_REQUEST: 'GET_EMPLOYEE_LIST_REQUEST',
  GET_EMPLOYEE_LIST_SUCCESS: 'GET_EMPLOYEE_LIST_SUCCESS',
  GET_EMPLOYEE_LIST_ERROR: 'GET_EMPLOYEE_LIST_ERROR',
}

const GET_EMPLOYEE_DETAILS = {
  GET_EMPLOYEE_DETAILS_REQUEST: 'GET_EMPLOYEE_DETAILS_REQUEST',
  GET_EMPLOYEE_DETAILS_SUCCESS: 'GET_EMPLOYEE_DETAILS_SUCCESS',
  GET_EMPLOYEE_DETAILS_ERROR: 'GET_EMPLOYEE_DETAILS_ERROR',
}

const UPDATE_EMPLOYEE = {
  UPDATE_EMPLOYEE_REQUEST: 'UPDATE_EMPLOYEE_REQUEST',
  UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
  UPDATE_EMPLOYEE_ERROR: 'UPDATE_EMPLOYEE_ERROR',
}

const UPDATE_EMPLOYEE_STATUS = {
  UPDATE_EMPLOYEE_STATUS_REQUEST: 'UPDATE_EMPLOYEE_STATUS_REQUEST',
  UPDATE_EMPLOYEE_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_STATUS_SUCCESS',
  UPDATE_EMPLOYEE_STATUS_ERROR: 'UPDATE_EMPLOYEE_STATUS_ERROR',
}

const UPLOAD_EMPLOYEE = {
  UPLOAD_EMPLOYEE_REQUEST: 'UPLOAD_EMPLOYEE_REQUEST',
  UPLOAD_EMPLOYEE_SUCCESS: 'UPLOAD_EMPLOYEE_SUCCESS',
  UPLOAD_EMPLOYEE_ERROR: 'UPLOAD_EMPLOYEE_ERROR',
}
const EMPLOYEE_ASSIGNMENT_HISTORY = {
  EMPLOYEE_ASSIGNMENT_HISTORY_REQUEST: 'EMPLOYEE_ASSIGNMENT_HISTORY_REQUEST',
  EMPLOYEE_ASSIGNMENT_HISTORY_SUCCESS: 'EMPLOYEE_ASSIGNMENT_HISTORY_SUCCESS',
  EMPLOYEE_ASSIGNMENT_HISTORY_ERROR: 'EMPLOYEE_ASSIGNMENT_HISTORY_ERROR',
}
const EMPLOYEE_JOB_DESCRIPTION_ASSIGNED = {
  EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_REQUEST:
    'EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_REQUEST',
  EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_SUCCESS:
    'EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_SUCCESS',
  EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_ERROR:
    'EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_ERROR',
}

const EMPLOYEE_JOB_DESCRIPTION_DETAILS = {
  EMPLOYEE_JOB_DESCRIPTION_DETAILS_REQUEST:
    'EMPLOYEE_JOB_DESCRIPTION_DETAILS_REQUEST',
  EMPLOYEE_JOB_DESCRIPTION_DETAILS_SUCCESS:
    'EMPLOYEE_JOB_DESCRIPTION_DETAILS_SUCCESS',
  EMPLOYEE_JOB_DESCRIPTION_DETAILS_ERROR:
    'EMPLOYEE_JOB_DESCRIPTION_DETAILS_ERROR',
}

export {
  ADD_EMPLOYEE,
  EMPLOYEE_ASSIGNMENT_HISTORY,
  EMPLOYEE_JOB_DESCRIPTION_ASSIGNED,
  EMPLOYEE_JOB_DESCRIPTION_DETAILS,
  GET_EMPLOYEE_DETAILS,
  GET_EMPLOYEE_LIST,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_STATUS,
  UPLOAD_EMPLOYEE,
}
