/* eslint-disable @typescript-eslint/no-empty-function */
import { Provider } from 'react-redux'

import store from '@redux/store'
import Routes from '@routes/routes'

import './App.css'

function App() {
  //for to not show any warnings
  // eslint-disable-next-line no-console
  console.warn = () => {}
  return (
    <Provider store={store}>
      <div className="app d-flex flex-column">
        <Routes />
      </div>
    </Provider>
  )
}

export default App
