const QUESTION_PAPER_LIST = {
  QUESTION_PAPER_LIST_REQUEST: 'QUESTION_PAPER_LIST_REQUEST',
  QUESTION_PAPER_LIST_SUCCESS: 'QUESTION_PAPER_LIST_SUCCESS',
  QUESTION_PAPER_LIST_ERROR: 'QUESTION_PAPER_LIST_ERROR',
}

const QUESTION_PAPER_DETAILS = {
  QUESTION_PAPER_DETAILS_REQUEST: 'QUESTION_PAPER_DETAILS_REQUEST',
  QUESTION_PAPER_DETAILS_SUCCESS: 'QUESTION_PAPER_DETAILS_SUCCESS',
  QUESTION_PAPER_DETAILS_ERROR: 'QUESTION_PAPER_DETAILS_ERROR',
}

const QUESTION_PAPER_DELETE = {
  QUESTION_PAPER_DELETE_REQUEST: 'QUESTION_PAPER_DELETE_REQUEST',
  QUESTION_PAPER_DELETE_SUCCESS: 'QUESTION_PAPER_DELETE_SUCCESS',
  QUESTION_PAPER_DELETE_ERROR: 'QUESTION_PAPER_DELETE_ERROR',
}

const QUESTION_PAPER_UPLOAD = {
  QUESTION_PAPER_UPLOAD_REQUEST: 'QUESTION_PAPER_UPLOAD_REQUEST',
  QUESTION_PAPER_UPLOAD_SUCCESS: 'QUESTION_PAPER_UPLOAD_SUCCESS',
  QUESTION_PAPER_UPLOAD_ERROR: 'QUESTION_PAPER_UPLOAD_ERROR',
}

const SET_CART_ITEMS = {
  SET_CART_ITEMS: 'SET_CART_ITEMS',
}

const REMOVE_CART_ITEMS = {
  REMOVE_CART_ITEMS: 'REMOVE_CART_ITEMS',
}

const UPLOAD_PAPER_FILE = {
  UPLOAD_PAPER_FILE_REQUEST: 'UPLOAD_PAPER_FILE_REQUEST',
  UPLOAD_PAPER_FILE_SUCCESS: 'UPLOAD_PAPER_FILE_SUCCESS',
  UPLOAD_PAPER_FILE_ERROR: 'UPLOAD_PAPER_FILE_ERROR',
}

const PAPER_DETAILS = { PAPER_DETAILS: 'PAPER_DETAILS' }

const CREATE_PAPER = {
  CREATE_PAPER_REQUEST: 'CREATE_PAPER_REQUEST',
  CREATE_PAPER_SUCCESS: 'CREATE_PAPER_SUCCESS',
  CREATE_PAPER_ERROR: 'CREATE_PAPER_ERROR',
}

const GET_EXAM_TYPES = {
  GET_EXAM_TYPES_REQUEST: 'GET_EXAM_TYPES_REQUEST',
  GET_EXAM_TYPES_SUCCESS: 'GET_EXAM_TYPES_SUCCESS',
  GET_EXAM_TYPES_ERROR: 'GET_EXAM_TYPES_ERROR',
}

const EDIT_QUESTION = {
  EDIT_QUESTION_REQUEST: 'EDIT_QUESTION_REQUEST',
  EDIT_QUESTION_SUCCESS: 'EDIT_QUESTION_SUCCESS',
  EDIT_QUESTION_ERROR: 'EDIT_QUESTION_ERROR',
}
const DELETE_QUESTION = {
  DELETE_QUESTION_REQUEST: 'DELETE_QUESTION_REQUEST',
  DELETE_QUESTION_SUCCESS: 'DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_ERROR: 'DELETE_QUESTION_ERROR',
}

const EDIT_QUESTION_PAPER = {
  EDIT_QUESTION_PAPER_REQUEST: 'EDIT_QUESTION_PAPER_REQUEST',
  EDIT_QUESTION_PAPER_SUCCESS: 'EDIT_QUESTION_PAPER_SUCCESS',
  EDIT_QUESTION_PAPER_ERROR: 'EDIT_QUESTION_PAPER_ERROR',
}

const GET_RECRUITMENT_QUESTION_PAPER_DETAILS = {
  GET_RECRUITMENT_QUESTION_PAPER_DETAILS_REQUEST:
    'GET_RECRUITMENT_QUESTION_PAPER_DETAILS_REQUEST',
  GET_RECRUITMENT_QUESTION_PAPER_DETAILS_SUCCESS:
    'GET_RECRUITMENT_QUESTION_PAPER_DETAILS_SUCCESS',
  GET_RECRUITMENT_QUESTION_PAPER_DETAILS_ERROR:
    'GET_RECRUITMENT_QUESTION_PAPER_DETAILS_ERROR',
}

const GET_OVERALL_QUESTION_PAPER_RATING = {
  GET_OVERALL_QUESTION_PAPER_RATING_REQUEST:
    'GET_OVERALL_QUESTION_PAPER_RATING_REQUEST',
  GET_OVERALL_QUESTION_PAPER_RATING_SUCCESS:
    'GET_OVERALL_QUESTION_PAPER_RATING_SUCCESS',
  GET_OVERALL_QUESTION_PAPER_RATING_ERROR:
    'GET_OVERALL_QUESTION_PAPER_RATING_ERROR',
}

const GET_QUESTION_PAPER_RATING_LIST = {
  GET_QUESTION_PAPER_RATING_LIST_REQUEST:
    'GET_QUESTION_PAPER_RATING_LIST_REQUEST',
  GET_QUESTION_PAPER_RATING_LIST_SUCCESS:
    'GET_QUESTION_PAPER_RATING_LIST_SUCCESS',
  GET_QUESTION_PAPER_RATING_LIST_ERROR: 'GET_QUESTION_PAPER_RATING_LIST_ERROR',
}

const BOOKMARK_QUESTION_PAPER = {
  BOOKMARK_QUESTION_PAPER_REQUEST: 'BOOKMARK_QUESTION_PAPER_REQUEST',
  BOOKMARK_QUESTION_PAPER_SUCCESS: 'BOOKMARK_QUESTION_PAPER_SUCCESS',
  BOOKMARK_QUESTION_PAPER_ERROR: 'BOOKMARK_QUESTION_PAPER_ERROR',
}

export {
  BOOKMARK_QUESTION_PAPER,
  CREATE_PAPER,
  DELETE_QUESTION,
  EDIT_QUESTION,
  EDIT_QUESTION_PAPER,
  GET_EXAM_TYPES,
  GET_OVERALL_QUESTION_PAPER_RATING,
  GET_QUESTION_PAPER_RATING_LIST,
  GET_RECRUITMENT_QUESTION_PAPER_DETAILS,
  PAPER_DETAILS,
  QUESTION_PAPER_DELETE,
  QUESTION_PAPER_DETAILS,
  QUESTION_PAPER_LIST,
  QUESTION_PAPER_UPLOAD,
  REMOVE_CART_ITEMS,
  SET_CART_ITEMS,
  UPLOAD_PAPER_FILE,
}
