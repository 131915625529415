const EXAM_LIST = {
  EXAM_LIST_REQUEST: 'EXAM_LIST_REQUEST',
  EXAM_LIST_SUCCESS: 'EXAM_LIST_SUCCESS',
  EXAM_LIST_ERROR: 'EXAM_LIST_ERROR',
  EXAM_LIST_BY_ID_REQUEST: 'EXAM_LIST_BY_ID_REQUEST',
  EXAM_LIST_BY_ID_SUCCESS: 'EXAM_LIST_BY_ID_SUCCESS',
  EXAM_LIST_BY_ID_ERROR: 'EXAM_LIST_BY_ID_ERROR',
}

const EXAM_DETAILS = {
  EXAM_DETAILS_REQUEST: 'EXAM_DETAILS_REQUEST',
  EXAM_DETAILS_SUCCESS: 'EXAM_DETAILS_SUCCESS',
  EXAM_DETAILS_ERROR: 'EXAM_DETAILS_ERROR',
}

const EXAM_DELETE = {
  EXAM_DELETE_REQUEST: 'EXAM_DELETE_REQUEST',
  EXAM_DELETE_SUCCESS: 'EXAM_DELETE_SUCCESS',
  EXAM_DELETE_ERROR: 'EXAM_DELETE_ERROR',
}

const EXAM_CREATE = {
  EXAM_CREATE_REQUEST: 'EXAM_CREATE_REQUEST',
  EXAM_CREATE_SUCCESS: 'EXAM_CREATE_SUCCESS',
  EXAM_CREATE_ERROR: 'EXAM_CREATE_ERROR',
}

const EXAM_UPDATE = {
  EXAM_UPDATE_REQUEST: 'EXAM_UPDATE_REQUEST',
  EXAM_UPDATE_SUCCESS: 'EXAM_UPDATE_SUCCESS',
  EXAM_UPDATE_ERROR: 'EXAM_UPDATE_ERROR',
}

const EXAM_TYPE_LIST = {
  EXAM_TYPE_LIST_REQUEST: 'EXAM_TYPE_LIST_REQUEST',
  EXAM_TYPE_LIST_SUCCESS: 'EXAM_TYPE_LIST_SUCCESS',
  EXAM_TYPE_LIST_ERROR: 'EXAM_TYPE_LIST_ERROR',
}

const ASSIGN_EXAM = {
  ASSIGN_EXAM_REQUEST: 'ASSIGN_EXAM_REQUEST',
  ASSIGN_EXAM_SUCCESS: 'ASSIGN_EXAM_SUCCESS',
  ASSIGN_EXAM_ERROR: 'ASSIGN_EXAM_ERROR',
}

const REASONING_TYPE_LIST = {
  REASONING_TYPE_LIST_REQUEST: 'REASONING_TYPE_LIST_REQUEST',
  REASONING_TYPE_LIST_SUCCESS: 'REASONING_TYPE_LIST_SUCCESS',
  REASONING_TYPE_LIST_ERROR: 'REASONING_TYPE_LIST_ERROR',
}

const EXAM_USERS_LIST = {
  EXAM_USERS_LIST_REQUEST: 'EXAM_USERS_LIST_REQUEST',
  EXAM_USERS_LIST_SUCCESS: 'EXAM_USERS_LIST_SUCCESS',
  EXAM_USERS_LIST_ERROR: 'EXAM_USERS_LIST_ERROR',
}

const SUITABLE_FOR_LIST = {
  SUITABLE_FOR_LIST_REQUEST: 'SUITABLE_FOR_LIST_REQUEST',
  SUITABLE_FOR_LIST_SUCCESS: 'SUITABLE_FOR_LIST_SUCCESS',
  SUITABLE_FOR_LIST_ERROR: 'SUITABLE_FOR_LIST_ERROR',
}

const GENERATE_QUESTIONS_BY_TOPIC_LIST = {
  GENERATE_QUESTIONS_BY_TOPIC_LIST_REQUEST:
    'GENERATE_QUESTIONS_BY_TOPIC_LIST_REQUEST',
  GENERATE_QUESTIONS_BY_TOPIC_LIST_SUCCESS:
    'GENERATE_QUESTIONS_BY_TOPIC_LIST_SUCCESS',
  GENERATE_QUESTIONS_BY_TOPIC_LIST_ERROR:
    'GENERATE_QUESTIONS_BY_TOPIC_LIST_ERROR',
}

const GET_JD_EXAM_LIST = {
  GET_JD_EXAM_LIST_REQUEST: 'GET_JD_EXAM_LIST_REQUEST',
  GET_JD_EXAM_LIST_SUCCESS: 'GET_JD_EXAM_LIST_SUCCESS',
  GET_JD_EXAM_LIST_ERROR: 'GET_JD_EXAM_LIST_ERROR',
}

const GET_DETAILS_OF_EXAM = {
  GET_DETAILS_OF_EXAM_REQUEST: 'GET_DETAILS_OF_EXAM_REQUEST',
  GET_DETAILS_OF_EXAM_SUCCESS: 'GET_DETAILS_OF_EXAM_SUCCESS',
  GET_DETAILS_OF_EXAM_ERROR: 'GET_DETAILS_OF_EXAM_ERROR',
}

const GET_QUESTIONS_OF_EXAM_DETAILS = {
  GET_QUESTIONS_OF_EXAM_DETAILS_REQUEST:
    'GET_QUESTIONS_OF_EXAM_DETAILS_REQUEST',
  GET_QUESTIONS_OF_EXAM_DETAILS_SUCCESS:
    'GET_QUESTIONS_OF_EXAM_DETAILS_SUCCESS',
  GET_QUESTIONS_OF_EXAM_DETAILS_ERROR: 'GET_QUESTIONS_OF_EXAM_DETAILS_ERROR',
}

const GET_CANDIDATE_REPORT_LIST = {
  GET_CANDIDATE_REPORT_LIST_REQUEST: 'GET_CANDIDATE_REPORT_LIST_REQUEST',
  GET_CANDIDATE_REPORT_LIST_SUCCESS: 'GET_CANDIDATE_REPORT_LIST_SUCCESS',
  GET_CANDIDATE_REPORT_LIST_ERROR: 'GET_CANDIDATE_REPORT_LIST_ERROR',
}

const GET_CANDIDATE_REPORT = {
  GET_CANDIDATE_REPORT_REQUEST: 'GET_CANDIDATE_REPORT_REQUEST',
  GET_CANDIDATE_REPORT_SUCCESS: 'GET_CANDIDATE_REPORT_SUCCESS',
  GET_CANDIDATE_REPORT_ERROR: 'GET_CANDIDATE_REPORT_ERROR',
}

const GET_JD_CANDIDATE_LIST = {
  GET_JD_CANDIDATE_LIST_REQUEST: 'GET_JD_CANDIDATE_LIST_REQUEST',
  GET_JD_CANDIDATE_LIST_SUCCESS: 'GET_JD_CANDIDATE_LIST_SUCCESS',
  GET_JD_CANDIDATE_LIST_ERROR: 'GET_JD_CANDIDATE_LIST_ERROR',
  RESET_JD_CANDIDATE_LIST: 'RESET_JD_CANDIDATE_LIST',
}

const GET_CANDIDATE_SUBMISSION_REPORT_LIST = {
  GET_CANDIDATE_SUBMISSION_REPORT_LIST_REQUEST:
    'GET_CANDIDATE_SUBMISSION_REPORT_LIST_REQUEST',
  GET_CANDIDATE_SUBMISSION_REPORT_LIST_SUCCESS:
    'GET_CANDIDATE_SUBMISSION_REPORT_LIST_SUCCESS',
  GET_CANDIDATE_SUBMISSION_REPORT_LIST_ERROR:
    'GET_CANDIDATE_SUBMISSION_REPORT_LIST_ERROR',
}

const ADD_REVIEW_AND_COMMENT = {
  ADD_REVIEW_AND_COMMENT_REQUEST: 'ADD_REVIEW_AND_COMMENT_REQUEST',
  ADD_REVIEW_AND_COMMENT_SUCCESS: 'ADD_REVIEW_AND_COMMENT_SUCCESS',
  ADD_REVIEW_AND_COMMENT_ERROR: 'ADD_REVIEW_AND_COMMENT_ERROR',
}

const GET_REVIEW_AND_COMMENT = {
  GET_REVIEW_AND_COMMENT_REQUEST: 'GET_REVIEW_AND_COMMENT_REQUEST',
  GET_REVIEW_AND_COMMENT_SUCCESS: 'GET_REVIEW_AND_COMMENT_SUCCESS',
  GET_REVIEW_AND_COMMENT_ERROR: 'GET_REVIEW_AND_COMMENT_ERROR',
}

const CANDIDATE_BOOKMARK = {
  CANDIDATE_BOOKMARK_REQUEST: 'CANDIDATE_BOOKMARK_REQUEST',
  CANDIDATE_BOOKMARK_SUCCESS: 'CANDIDATE_BOOKMARK_SUCCESS',
  CANDIDATE_BOOKMARK_ERROR: 'CANDIDATE_BOOKMARK_ERROR',
}

//**********  STUDENT DATA DOWNLOAD EXCEL *************/
const CANDIDATE_DATA_DOWNLOAD_EXCEL = {
  CANDIDATE_DATA_DOWNLOAD_EXCEL_REQUEST:
    'CANDIDATE_DATA_DOWNLOAD_EXCEL_REQUEST',
  CANDIDATE_DATA_DOWNLOAD_EXCEL_SUCCESS:
    'CANDIDATE_DATA_DOWNLOAD_EXCEL_SUCCESS',
  CANDIDATE_DATA_DOWNLOAD_EXCEL_ERROR: 'CANDIDATE_DATA_DOWNLOAD_EXCEL_ERROR',
}

const RESET_CANDIDATE_DOWNLOAD_REPORT = 'RESET_CANDIDATE_DOWNLOAD_REPORT'

const ASSIGN_VIEW_EXAM_REPORT_PERMISSION = {
  GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_REQUEST:
    'GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_REQUEST',
  GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_SUCCESS:
    'GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_SUCCESS',
  GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_ERROR:
    'GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_ERROR',
}

const CANDIDATE_DATA = {
  CANDIDATE_DATA_REQUEST: 'CANDIDATE_DATA_REQUEST',
  CANDIDATE_DATA_SUCCESS: 'CANDIDATE_DATA_SUCCESS',
  CANDIDATE_DATA_ERROR: 'CANDIDATE_DATA_ERROR',
}

export {
  ADD_REVIEW_AND_COMMENT,
  ASSIGN_EXAM,
  ASSIGN_VIEW_EXAM_REPORT_PERMISSION,
  CANDIDATE_BOOKMARK,
  CANDIDATE_DATA,
  CANDIDATE_DATA_DOWNLOAD_EXCEL,
  EXAM_CREATE,
  EXAM_DELETE,
  EXAM_DETAILS,
  EXAM_LIST,
  EXAM_TYPE_LIST,
  EXAM_UPDATE,
  EXAM_USERS_LIST,
  GENERATE_QUESTIONS_BY_TOPIC_LIST,
  GET_CANDIDATE_REPORT,
  GET_CANDIDATE_REPORT_LIST,
  GET_CANDIDATE_SUBMISSION_REPORT_LIST,
  GET_DETAILS_OF_EXAM,
  GET_JD_CANDIDATE_LIST,
  GET_JD_EXAM_LIST,
  GET_QUESTIONS_OF_EXAM_DETAILS,
  GET_REVIEW_AND_COMMENT,
  REASONING_TYPE_LIST,
  RESET_CANDIDATE_DOWNLOAD_REPORT,
  SUITABLE_FOR_LIST,
}
