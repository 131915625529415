const ORGANISATION_CREATE = {
  ORGANISATION_CREATE_REQUEST: 'ORGANISATION_CREATE_REQUEST',
  ORGANISATION_CREATE_SUCCESS: 'ORGANISATION_CREATE_SUCCESS',
  ORGANISATION_CREATE_ERROR: 'ORGANISATION_CREATE_ERROR',
}

const ORGANISATION_USER_MAPPING_CREATE = {
  ORGANISATION_USER_MAPPING_CREATE_REQUEST:
    'ORGANISATION_USER_MAPPING_CREATE_REQUEST',
  ORGANISATION_USER_MAPPING_CREATE_SUCCESS:
    'ORGANISATION_USER_MAPPING_CREATE_SUCCESS',
  ORGANISATION_USER_MAPPING_CREATE_ERROR:
    'ORGANISATION_USER_MAPPING_CREATE_ERROR',
}

const ORGANISATION_DETAILS = {
  ORGANISATION_DETAILS_REQUEST: 'ORGANISATION_DETAILS_REQUEST',
  ORGANISATION_DETAILS_SUCCESS: 'ORGANISATION_DETAILS_SUCCESS',
  ORGANISATION_DETAILS_ERROR: 'ORGANISATION_DETAILS_ERROR',
}

const ORGANISATION_UPDATE = {
  ORGANISATION_UPDATE_REQUEST: 'ORGANISATION_UPDATE_REQUEST',
  ORGANISATION_UPDATE_SUCCESS: 'ORGANISATION_UPDATE_SUCCESS',
  ORGANISATION_UPDATE_ERROR: 'ORGANISATION_UPDATE_ERROR',
}

const EXPERTISE_LIST = {
  EXPERTISE_LIST_REQUEST: 'EXPERTISE_LIST_REQUEST',
  EXPERTISE_LIST_SUCCESS: 'EXPERTISE_LIST_SUCCESS',
  EXPERTISE_LIST_ERROR: 'EXPERTISE_LIST_ERROR',
}

const SERVICE_LIST = {
  SERVICE_LIST_REQUEST: 'SERVICE_LIST_REQUEST',
  SERVICE_LIST_SUCCESS: 'SERVICE_LIST_SUCCESS',
  SERVICE_LIST_ERROR: 'SERVICE_LIST_ERROR',
}

const TOOL_LIST = {
  TOOL_LIST_REQUEST: 'TOOL_LIST_REQUEST',
  TOOL_LIST_SUCCESS: 'TOOL_LIST_SUCCESS',
  TOOL_LIST_ERROR: 'TOOL_LIST_ERROR',
}

const AGENCY_DETAILS = {
  AGENCY_DETAILS_REQUEST: 'AGENCY_DETAILS_REQUEST',
  AGENCY_DETAILS_SUCCESS: 'AGENCY_DETAILS_SUCCESS',
  AGENCY_DETAILS_ERROR: 'AGENCY_DETAILS_ERROR',
}

export {
  AGENCY_DETAILS,
  EXPERTISE_LIST,
  ORGANISATION_CREATE,
  ORGANISATION_DETAILS,
  ORGANISATION_UPDATE,
  ORGANISATION_USER_MAPPING_CREATE,
  SERVICE_LIST,
  TOOL_LIST,
}
