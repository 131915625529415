const CANDIDATE_RESUME_LIST = {
  CANDIDATE_RESUME_LIST_REQUEST: 'CANDIDATE_RESUME_LIST_REQUEST',
  CANDIDATE_RESUME_LIST_SUCCESS: 'CANDIDATE_RESUME_LIST_SUCCESS',
  CANDIDATE_RESUME_LIST_ERROR: 'CANDIDATE_RESUME_LIST_ERROR',
}

const ALL_CANDIDATE_LIST = {
  ALL_CANDIDATE_LIST_REQUEST: 'ALL_CANDIDATE_LIST_REQUEST',
  ALL_CANDIDATE_LIST_SUCCESS: 'ALL_CANDIDATE_LIST_SUCCESS',
  ALL_CANDIDATE_LIST_ERROR: 'ALL_CANDIDATE_LIST_ERROR',
}

export { ALL_CANDIDATE_LIST, CANDIDATE_RESUME_LIST }
