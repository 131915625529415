const GET_USER_MOVEMENT_IMAGES_ACTION = {
  USER_MOVEMENT_IMAGES_REQUEST: 'USER_MOVEMENT_IMAGES_REQUEST',
  USER_MOVEMENT_IMAGES_SUCCESS: 'USER_MOVEMENT_IMAGES_SUCCESS',
  USER_MOVEMENT_IMAGES_ERROR: 'USER_MOVEMENT_IMAGES_ERROR',
}

const GET_USER_MOVEMENT_IMAGES_ZIP_ACTION = {
  USER_MOVEMENT_IMAGES_ZIP_REQUEST: 'USER_MOVEMENT_IMAGES_ZIP_REQUEST',
  USER_MOVEMENT_IMAGES_ZIP_SUCCESS: 'USER_MOVEMENT_IMAGES_ZIP_SUCCESS',
  USER_MOVEMENT_IMAGES_ZIP_ERROR: 'USER_MOVEMENT_IMAGES_ZIP_ERROR',
}

export { GET_USER_MOVEMENT_IMAGES_ACTION, GET_USER_MOVEMENT_IMAGES_ZIP_ACTION }
