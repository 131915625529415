import { combineReducers } from 'redux'

import authReducer from '@redux/auth/authReducer'
import candidateManagementReducer from '@redux/candidate-management/candidateManagementReducer'
import commonReducer from '@redux/common/commonReducer'
import companiesReducer from '@redux/companies/companiesReducer'
import employeeReducer from '@redux/employee-management/employeeReducer'
import examReducer from '@redux/exam/examReducer'
import jobDescriptionReducer from '@redux/job-description/jobDescriptionReducer'
import organisationReducer from '@redux/organisation/organisationReducer'
import proctoringReducer from '@redux/proctoring-exam/proctoringExamReducer'
import questionPaperReducer from '@redux/question-paper/questionPaperReducer'
import resumeLibraryReducer from '@redux/resume-library/resumeLibraryReducer'

// Combine all reducers as root reducer
export default combineReducers({
  authReducer,
  examReducer,
  questionPaperReducer,
  employeeReducer,
  companiesReducer,
  organisationReducer,
  jobDescriptionReducer,
  commonReducer,
  proctoringReducer,
  resumeLibraryReducer,
  candidateManagementReducer,
})
