const REGISTER = {
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',
}

const ACCOUNT_VERIFICATION = {
  ACCOUNT_VERIFICATION_REQUEST: 'ACCOUNT_VERIFICATION_REQUEST',
  ACCOUNT_VERIFICATION_SUCCESS: 'ACCOUNT_VERIFICATION_SUCCESS',
  ACCOUNT_VERIFICATION_ERROR: 'ACCOUNT_VERIFICATION_ERROR',
}

const CHANGE_PASSWORD = {
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
}

const RESET_PASSWORD = {
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
}

const FORGOT_PASSWORD = {
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
}

const LOGIN = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
}

const LOGOUT = {
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
}

const PROFILE_UPDATE = {
  PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_ERROR: 'PROFILE_UPDATE_ERROR',
}

const GET_PROFILE_DETAILS = {
  GET_PROFILE_DETAILS_REQUEST: 'GET_PROFILE_DETAILS_REQUEST',
  GET_PROFILE_DETAILS_SUCCESS: 'GET_PROFILE_DETAILS_SUCCESS',
  GET_PROFILE_DETAILS_ERROR: 'GET_PROFILE_DETAILS_ERROR',
}

const UPDATE_PASSWORD = {
  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_ERROR: 'UPDATE_PASSWORD_ERROR',
}

const RESEND_EMAIL_OTP = {
  RESEND_EMAIL_OTP_REQUEST: 'RESEND_EMAIL_OTP_REQUEST',
  RESEND_EMAIL_OTP_SUCCESS: 'RESEND_EMAIL_OTP_SUCCESS',
  RESEND_EMAIL_OTP_ERROR: 'RESEND_EMAIL_OTP_ERROR',
}

const VARIFY_EMAIL_OTP = {
  VARIFY_EMAIL_OTP_REQUEST: 'VARIFY_EMAIL_OTP_REQUEST',
  VARIFY_EMAIL_OTP_SUCCESS: 'VARIFY_EMAIL_OTP_SUCCESS',
  VARIFY_EMAIL_OTP_ERROR: 'VARIFY_EMAIL_OTP_ERROR',
}

const SEND_EMAIL_VARIFICATION_CODE = {
  SEND_EMAIL_VARIFICATION_CODE_REQUEST: 'SEND_EMAIL_VARIFICATION_CODE_REQUEST',
  SEND_EMAIL_VARIFICATION_CODE_SUCCESS: 'SEND_EMAIL_VARIFICATION_CODE_SUCCESS',
  SEND_EMAIL_VARIFICATION_CODE_ERROR: 'SEND_EMAIL_VARIFICATION_CODE_ERROR',
}

const VARIFY_EMAIL = {
  VARIFY_EMAIL_REQUEST: 'VARIFY_EMAIL_REQUEST',
  VARIFY_EMAIL_SUCCESS: 'VARIFY_EMAIL_SUCCESS',
  VARIFY_EMAIL_ERROR: 'VARIFY_EMAIL_ERROR',
}

const RESEND_USER_EMAIL_VARIFICATION_CODE = {
  RESEND_USER_EMAIL_VARIFICATION_CODE_REQUEST:
    'RESEND_USER_EMAIL_VARIFICATION_CODE_REQUEST',
  RESEND_USER_EMAIL_VARIFICATION_CODE_SUCCESS:
    'RESEND_USER_EMAIL_VARIFICATION_CODE_SUCCESS',
  RESEND_USER_EMAIL_VARIFICATION_CODE_ERROR:
    'RESEND_USER_EMAIL_VARIFICATION_CODE_ERROR',
}

export {
  ACCOUNT_VERIFICATION,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  GET_PROFILE_DETAILS,
  LOGIN,
  LOGOUT,
  PROFILE_UPDATE,
  REGISTER,
  RESEND_EMAIL_OTP,
  RESEND_USER_EMAIL_VARIFICATION_CODE,
  RESET_PASSWORD,
  SEND_EMAIL_VARIFICATION_CODE,
  UPDATE_PASSWORD,
  VARIFY_EMAIL,
  VARIFY_EMAIL_OTP,
}
