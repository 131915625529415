import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  ASSIGN_RECRUITER_TO_JD,
  CANDIDATE_SUBMISSION_STATE,
  GET_CANDIDATE_LIST,
  GET_JOB_DESCRIPTION_LIST,
  GET_JOB_DESCRIPTION_SUBMISSION_LIST,
  GET_RECRUITER_LIST,
  JOB_DESCRIPTION_BOOKMARK,
  REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES,
  SUBMIT_RESUME_TO_JD,
} from '@redux/job-description/jobDescriptionConst'
import {
  ActionTypes,
  JobDescriptionData,
} from '@redux/job-description/jobDescriptionType'

const initialState = {
  isLoadingJobDescriptionList: false,
  jobDescriptionList: [],
  isLoadingRecruiterList: false,
  recruiterList: [],
  isAssigningRecruiter: false,
  isLoadingCandidateList: false,
  candidateList: [],
  isLoadingJobDescriptionSubmissionList: false,
  jobDescriptionSubmissionList: {
    data: {
      results: [],
    },
    count: 0,
    submission_data: {},
  },
  isLoadingRemoveCandidateFromJDSubmissionState: false,
  isLoadingJDBookmark: false,
  isLoadingCandidateSubmission: false,
  isLoadingSubmitResumeToJD: false,
}

const jobDescriptionReducer = persistReducer(
  {
    storage,
    key: 'job-description',
    whitelist: [],
  },
  (state: JobDescriptionData = initialState, action: ActionTypes) => {
    switch (action.type) {
      // ==================================================
      // Job Description list
      // ==================================================
      case GET_JOB_DESCRIPTION_LIST.GET_JOB_DESCRIPTION_REQUEST:
        return {
          ...state,
          isLoadingJobDescriptionList: true,
        }

      case GET_JOB_DESCRIPTION_LIST.GET_JOB_DESCRIPTION_SUCCESS:
        return {
          ...state,
          isLoadingJobDescriptionList: false,
          jobDescriptionList: action.payload,
        }

      case GET_JOB_DESCRIPTION_LIST.GET_JOB_DESCRIPTION_ERROR:
        return {
          ...state,
          isLoadingJobDescriptionListList: false,
        }

      case GET_RECRUITER_LIST.GET_RECRUITER_REQUEST:
        return {
          ...state,
          isLoadingRecruiterList: true,
        }

      case GET_RECRUITER_LIST.GET_RECRUITER_SUCCESS:
        return {
          ...state,
          isLoadingRecruiterList: false,
          recruiterList: action.payload,
        }

      case GET_RECRUITER_LIST.GET_RECRUITER_ERROR:
        return {
          ...state,
          isLoadingRecruiterList: false,
        }

      case ASSIGN_RECRUITER_TO_JD.ASSIGN_RECRUITER_TO_JD_REQUEST:
        return {
          ...state,
          isAssigningRecruiter: true,
        }

      case ASSIGN_RECRUITER_TO_JD.ASSIGN_RECRUITER_TO_JD_SUCCESS:
        return {
          ...state,
          isAssigningRecruiter: false,
        }

      case ASSIGN_RECRUITER_TO_JD.ASSIGN_RECRUITER_TO_JD_ERROR:
        return {
          ...state,
          isAssigningRecruiter: false,
        }

      case GET_CANDIDATE_LIST.GET_CANDIDATE_REQUEST:
        return {
          ...state,
          isLoadingCandidateList: true,
        }

      case GET_CANDIDATE_LIST.GET_CANDIDATE_SUCCESS:
        return {
          ...state,
          isLoadingCandidateList: false,
          candidateList: action.payload,
        }

      case GET_CANDIDATE_LIST.GET_CANDIDATE_ERROR:
        return {
          ...state,
          isLoadingCandidateList: false,
        }

      case GET_JOB_DESCRIPTION_SUBMISSION_LIST.GET_JOB_DESCRIPTION_SUBMISSION_REQUEST:
        return {
          ...state,
          isLoadingJobDescriptionSubmissionList: true,
        }

      case GET_JOB_DESCRIPTION_SUBMISSION_LIST.GET_JOB_DESCRIPTION_SUBMISSION_SUCCESS:
        return {
          ...state,
          isLoadingJobDescriptionSubmissionList: false,
          jobDescriptionSubmissionList: action.payload,
        }

      case GET_JOB_DESCRIPTION_SUBMISSION_LIST.GET_JOB_DESCRIPTION_SUBMISSION_ERROR:
        return {
          ...state,
          isLoadingJobDescriptionSubmissionList: false,
        }

      case REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES.REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_REQUEST:
        return {
          ...state,
          isLoadingRemoveCandidateFromJDSubmissionState: true,
        }

      case REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES.REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_SUCCESS:
        return {
          ...state,
          isLoadingRemoveCandidateFromJDSubmissionState: false,
        }

      case REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES.REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_ERROR:
        return {
          ...state,
          isLoadingRemoveCandidateFromJDSubmissionState: false,
        }

      case JOB_DESCRIPTION_BOOKMARK.JOB_DESCRIPTION_BOOKMARK_REQUEST:
        return {
          ...state,
          isLoadingJDBookmark: true,
        }

      case JOB_DESCRIPTION_BOOKMARK.JOB_DESCRIPTION_BOOKMARK_SUCCESS:
        return {
          ...state,
          isLoadingJDBookmark: false,
        }

      case JOB_DESCRIPTION_BOOKMARK.JOB_DESCRIPTION_BOOKMARK_ERROR:
        return {
          ...state,
          isLoadingJDBookmark: false,
        }

      case CANDIDATE_SUBMISSION_STATE.CANDIDATE_SUBMISSION_STATE_REQUEST:
        return {
          ...state,
          isLoadingCandidateSubmission: true,
        }

      case CANDIDATE_SUBMISSION_STATE.CANDIDATE_SUBMISSION_STATE_SUCCESS:
        return {
          ...state,
          isLoadingCandidateSubmission: false,
        }

      case CANDIDATE_SUBMISSION_STATE.CANDIDATE_SUBMISSION_STATE_ERROR:
        return {
          ...state,
          isLoadingCandidateSubmission: false,
        }

      case SUBMIT_RESUME_TO_JD.SUBMIT_RESUME_TO_JD_REQUEST:
        return {
          ...state,
          isLoadingSubmitResumeToJD: true,
        }

      case SUBMIT_RESUME_TO_JD.SUBMIT_RESUME_TO_JD_SUCCESS:
        return {
          ...state,
          isLoadingSubmitResumeToJD: false,
        }

      case SUBMIT_RESUME_TO_JD.SUBMIT_RESUME_TO_JD_ERROR:
        return {
          ...state,
          isLoadingSubmitResumeToJD: false,
        }

      default:
        return state
    }
  }
)

export default jobDescriptionReducer
