const GET_COMPANIES_LIST = {
  GET_COMPANIES_LIST_REQUEST: 'GET_COMPANIES_LIST_REQUEST',
  GET_COMPANIES_LIST_SUCCESS: 'GET_COMPANIES_LIST_SUCCESS',
  GET_COMPANIES_LIST_ERROR: 'GET_COMPANIES_LIST_ERROR',
}

const GET_COMPANY_DETAILS = {
  GET_COMPANY_DETAILS_REQUEST: 'GET_COMPANY_DETAILS_REQUEST',
  GET_COMPANY_DETAILS_SUCCESS: 'GET_COMPANY_DETAILS_SUCCESS',
  GET_COMPANY_DETAILS_ERROR: 'GET_COMPANY_DETAILS_ERROR',
}
const GET_COMPANY_ACTIVE_JD = {
  GET_COMPANY_ACTIVE_JD_REQUEST: 'GET_COMPANY_ACTIVE_JD_REQUEST',
  GET_COMPANY_ACTIVE_JD_SUCCESS: 'GET_COMPANY_ACTIVE_JD_SUCCESS',
  GET_COMPANY_ACTIVE_JD_ERROR: 'GET_COMPANY_ACTIVE_JD_ERROR',
}

const COMPANY_BOOKMARK = {
  COMPANY_BOOKMARK_REQUEST: 'COMPANY_BOOKMARK_REQUEST',
  COMPANY_BOOKMARK_SUCCESS: 'COMPANY_BOOKMARK_SUCCESS',
  COMPANY_BOOKMARK_ERROR: 'COMPANY_BOOKMARK_ERROR',
}

const CONNECTION_REQUEST_UPDATE = {
  CONNECTION_REQUEST_UPDATE_REQUEST: 'CONNECTION_REQUEST_UPDATE_REQUEST',
  CONNECTION_REQUEST_UPDATE_SUCCESS: 'CONNECTION_REQUEST_UPDATE_SUCCESS',
  CONNECTION_REQUEST_UPDATE_ERROR: 'CONNECTION_REQUEST_UPDATE_ERROR',
}
const SENT_CONNECTION_REQUEST = {
  SENT_CONNECTION_REQUEST_REQUEST: 'SENT_CONNECTION_REQUEST_REQUEST',
  SENT_CONNECTION_REQUEST_SUCCESS: 'SENT_CONNECTION_REQUEST_SUCCESS',
  SENT_CONNECTION_REQUEST_ERROR: 'SENT_CONNECTION_REQUEST_ERROR',
}

const CITY_LIST = {
  CITY_LIST_REQUEST: 'CITY_LIST_REQUEST',
  CITY_LIST_SUCCESS: 'CITY_LIST_SUCCESS',
  CITY_LIST_ERROR: 'CITY_LIST_ERROR',
}

const BUSINESS_CATEGORY_LIST = {
  BUSINESS_CATEGORY_LIST_REQUEST: 'BUSINESS_CATEGORY_LIST_REQUEST',
  BUSINESS_CATEGORY_LIST_SUCCESS: 'BUSINESS_CATEGORY_LIST_SUCCESS',
  BUSINESS_CATEGORY_LIST_ERROR: 'BUSINESS_CATEGORY_LIST_ERROR',
}

export {
  BUSINESS_CATEGORY_LIST,
  CITY_LIST,
  COMPANY_BOOKMARK,
  CONNECTION_REQUEST_UPDATE,
  GET_COMPANIES_LIST,
  GET_COMPANY_ACTIVE_JD,
  GET_COMPANY_DETAILS,
  SENT_CONNECTION_REQUEST,
}
