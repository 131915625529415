import { lazy } from 'react'

const Login = lazy(() => import('@pages/auth/Login'))
const Register = lazy(() => import('@pages/auth/Register'))
const ForgotPassword = lazy(() => import('@pages/auth/ForgotPassword'))
const ResetPassword = lazy(() => import('@pages/auth/ResetPassword'))
const ConfirmEmail = lazy(() => import('@pages/auth/ConfirmEmail'))
const ChangePasswords = lazy(
  () => import('@pages/profile_setting/change_password/ChangePasswords')
)
const Dashboard = lazy(() => import('@pages/home/Dashboard'))
const MyProfile = lazy(() => import('@pages/profile_setting/profile/MyProfile'))
const PageNotFound = lazy(() => import('../components/PageNotFound'))
const OrganisationProfile = lazy(
  () => import('@pages/organisation/profile/OrganisationProfile')
)
const OrganisationProfileSetup = lazy(
  () =>
    import('@pages/organisation/organisation-profile-setup/OrganisationSetup')
)
const CompanyList = lazy(
  () => import('@pages/company/company_list/CompanyList')
)
const CompanyDetails = lazy(
  () => import('@pages/company/company_list/CompanyDetails')
)
const RequestToEmpanel = lazy(
  () => import('@pages/company/company_list/RequestEmpanel')
)
const ConnectionRequest = lazy(
  () => import('@pages/company/connection_request/ConnectionRequest')
)
const BookmarkedCompany = lazy(
  () => import('@pages/company/bookmarked/BookmarkedCompany')
)
const EmployeeManagement = lazy(
  () => import('@pages/organisation/organisation-management/EmployeeManagement')
)
const AddEmployee = lazy(
  () =>
    import(
      '@pages/organisation/organisation-management/add-new-employee/AddEmployee'
    )
)
const UploadEmployee = lazy(
  () =>
    import(
      '@pages/organisation/organisation-management/add-new-employee/UploadEmployee'
    )
)
const EmployeeDetail = lazy(
  () =>
    import(
      '@pages/organisation/organisation-management/employee-details/EmployeeDetail'
    )
)
const ClientAssigned = lazy(
  () =>
    import(
      '@pages/organisation/organisation-management/employee-details/employee-details-components/assignment-history-details/ClientAssigned'
    )
)
const JobDescription = lazy(
  () => import('@pages/job_description/JobDescription')
)
const JobDescriptionDetail = lazy(
  () => import('@pages/job_description/JobDescriptionDetail')
)
const AddCandidate = lazy(
  () => import('@pages/candidate_management/AddCandidate')
)
const CandidateList = lazy(() => import('@pages/job_description/CandidateList'))
const ViewCandidate = lazy(
  () => import('@pages/candidate_management/ViewCandidate')
)
const EditCandidateResumes = lazy(
  () => import('@pages/candidate_management/upload_resume/EditCandidate')
)
const RecruiterList = lazy(() => import('@pages/job_description/RecruiterList'))
const PreviewResumes = lazy(
  () => import('@pages/candidate_management/upload_resume/Preview')
)
const UploadCandidatesResumes = lazy(
  () => import('@pages/candidate_management/upload_resume/UploadCandidates')
)
const EditCandidate = lazy(
  () => import('@pages/candidate_management/upload_spreadsheet/EditCandidate')
)
const Preview = lazy(
  () => import('@pages/candidate_management/upload_spreadsheet/Preview')
)
const UploadCandidates = lazy(
  () =>
    import('@pages/candidate_management/upload_spreadsheet/UploadCandidates')
)
const ClientsDetails = lazy(
  () => import('@pages/company/client/ClientsDetails')
)
const ClientsList = lazy(() => import('@pages/company/client/ClientsList'))
const AssignExam = lazy(
  () => import('@pages/create_exam/create_new_exam/AssignExam')
)
const CreateExamQuestionsLibrary = lazy(
  () =>
    import(
      '@pages/create_exam/create_new_exam/auto_generate_exam/CreateExamQuestionsLibrary'
    )
)
const CreateExamSettingPreview = lazy(
  () => import('@pages/create_exam/create_new_exam/CreateExamSettingPreview')
)
const CreateNewExam = lazy(
  () => import('@pages/create_exam/create_new_exam/CreateNewExam')
)
const EditExamDetails = lazy(() => import('@pages/create_exam/EditExamDetails'))
const ExamReport = lazy(
  () => import('@pages/create_exam/exam_details/Exam-Report')
)
const ExamDetails = lazy(
  () => import('@pages/create_exam/exam_details/ExamDetails')
)
const ViewAnswer = lazy(
  () => import('@pages/create_exam/exam_details/View-Answer')
)
const HelpCenter = lazy(
  () => import('@pages/profile_setting/help_center/HelpCenter')
)
const HelpCenterFaqDetail = lazy(
  () => import('@pages/profile_setting/help_center/HelpCenterFaqDetail')
)
const HelpCenterFaqDetailReadmore = lazy(
  () => import('@pages/profile_setting/help_center/HelpCenterFaqDetailReadmore')
)
const Notifications = lazy(
  () => import('@pages/profile_setting/notification/Notifications')
)
const SystemSetting = lazy(
  () => import('@pages/profile_setting/system_setting/SystemSetting')
)
const TransactionHistory = lazy(
  () => import('@pages/profile_setting/transaction_history/TransactioHistory')
)
const PurchaseRequest = lazy(
  () => import('@pages/question-paper/purchase_request/PurchaseRequest')
)

const CandidateManagement = lazy(
  () => import('@pages/candidate_management/CandidateManagement')
)
const QuestionPaperPreview = lazy(
  () => import('@pages/question-paper/store/QuestionPaperPreview')
)
const QuestionPaperShoppingCart = lazy(
  () => import('@pages/question-paper/store/QuestionPaperShoppingCart')
)
const Shop = lazy(() => import('@pages/question-paper/store/Shop'))
const BookmarkedQuestionPapers = lazy(
  () => import('@pages/question-paper/store/BookmarkedQuestionPapers')
)
const QuestionPaperDetail = lazy(
  () =>
    import('@pages/question-paper/upload_question_paper/QuestionPaperDetail')
)
const QuestionPaperLibrary = lazy(
  () =>
    import('@pages/question-paper/upload_question_paper/QuestionPaperLibrary')
)
const Exams = lazy(() => import('@pages/create_exam/Exams'))
const QuestionPaperEdit = lazy(
  () =>
    import(
      '@pages/question-paper/upload_question_paper/question_paper_edit/QuestionPaperEdit'
    )
)
const QuestionPaperUpload = lazy(
  () =>
    import(
      '@pages/question-paper/upload_question_paper/question_paper_upload/QuestionPaperUpload'
    )
)

export const publicRoutes = [
  {
    path: '/auth/login',
    element: Login,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/auth/register',
    element: Register,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/auth/forgot-password',
    element: ForgotPassword,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/auth/confirm-email',
    element: ConfirmEmail,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/auth/reset-password',
    element: ResetPassword,
    sideBar: false,
    navBar: false,
  },
]
export const privateRoutes = [
  {
    path: '/home',
    element: Dashboard,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/myProfile',
    element: MyProfile,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/change-password',
    element: ChangePasswords,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/organisation-profile',
    element: OrganisationProfile,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/organisation-profile-setup',
    element: OrganisationProfileSetup,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/companies',
    element: CompanyList,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/company-details/:compId',
    element: CompanyDetails,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/request-to-empanel/:compId',
    element: RequestToEmpanel,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/connection-request',
    element: ConnectionRequest,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/bookmarked-company',
    element: BookmarkedCompany,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/employee-management',
    element: EmployeeManagement,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/add-employee',
    element: AddEmployee,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/upload-employee',
    element: UploadEmployee,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/employee-details/:empId',
    element: EmployeeDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/client-assign/:jdId',
    element: ClientAssigned,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/job-description',
    element: JobDescription,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/job-description-details/:id',
    element: JobDescriptionDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/candidate-list/:id',
    element: CandidateList,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/recruiter-list',
    element: RecruiterList,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/upload-question-paper',
    element: QuestionPaperUpload,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/question-paper-library',
    element: QuestionPaperLibrary,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/question-paper-details/:Id',
    element: QuestionPaperDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/shop',
    element: Shop,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/bookmarked-question-paper',
    element: BookmarkedQuestionPapers,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/question-paper-preview/:Id',
    element: QuestionPaperPreview,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/edit-question-paper',
    element: QuestionPaperEdit,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/shopping-cart',
    element: QuestionPaperShoppingCart,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/create-exam-question-paper-store',
    element: Shop,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/create-exam-question-paper-preview/:Id',
    element: QuestionPaperPreview,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/create-exam-shopping-cart',
    element: QuestionPaperShoppingCart,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/purchase-request',
    element: PurchaseRequest,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/client-list',
    element: ClientsList,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/candidate-management',
    element: CandidateManagement,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/add-candidate',
    element: AddCandidate,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/view-candidate',
    element: ViewCandidate,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/upload-candidates',
    element: UploadCandidates,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/edit-candidate',
    element: EditCandidate,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/candidates-preview',
    element: Preview,
    sideBar: true,
    navBar: true,
  },

  {
    path: '/candidates-preview-resumes',
    element: PreviewResumes,
    sideBar: true,
    navBar: true,
  },

  {
    path: '/upload-candidates-resumes',
    element: UploadCandidatesResumes,
    sideBar: true,
    navBar: true,
  },

  {
    path: '/edit-candidate-resumes',
    element: EditCandidateResumes,
    sideBar: true,
    navBar: true,
  },

  {
    path: '/exams',
    element: Exams,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/create-exam',
    element: CreateNewExam,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/page-not-found',
    element: PageNotFound,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/auto-generate',
    element: CreateExamQuestionsLibrary,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/create-exam-setting',
    element: CreateExamSettingPreview,
    sideBar: false,
    navBar: false,
  },
  {
    path: '/assign-exam/:examId',
    element: AssignExam,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/exam-details/:Id',
    element: ExamDetails,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/edit-exam-details/:Id',
    element: EditExamDetails,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/exam-report/:Id',
    element: ExamReport,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/view-answer',
    element: ViewAnswer,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/transaction-history',
    element: TransactionHistory,
    sideBar: true,
    navBar: true,
  },
  {
    path: '/system-setting',
    element: SystemSetting,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/notification',
    element: Notifications,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/help-center',
    element: HelpCenter,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/HelpCenterFaqDetail',
    element: HelpCenterFaqDetail,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/HelpCenterFaqDetailReadmore',
    element: HelpCenterFaqDetailReadmore,
    sideBar: false,
    navBar: true,
  },
  {
    path: '/client-details',
    element: ClientsDetails,
    sideBar: true,
    navBar: true,
  },
]
